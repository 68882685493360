import React, { Suspense, lazy } from "react";
import { Route, Routes, Navigate, useNavigate } from "react-router-dom";
import { usePermissionContext } from "../context/PremissionContext";
import Loading from "../components/base/Loading";
import StoreReportDashboard from "../views/store-dashboard/store-report-dashboard";
import InventorySummary from "../views/store-dashboard/inventory-summary";
// import PeriodicData from "../views/inventory/periodic-data";
import Upload from "../views/initial-download/upload";
import PurchaseReturnApproval from "../views/purchase-return-approval/purchase-return-approval";
import ViewPurchaseReturnApproval from "../views/purchase-return-approval/view-purchase-return-approval";
import BillMapping from "../views/bill-mapping/bill-mapping";
import MistakeTicket from "../views/mistake-ticket/mistake-ticket";
import AddMistakeTicket from "../views/mistake-ticket/add-mistake-ticket";
import ViewMistakeTicket from "../views/mistake-ticket/view-mistake-ticket";
import OpAddStockClose from "../views/stock-close/op-stock-close";
import OpAddStsaleClose from "../views/stsale-close/op-stsale-close";
import OpAddCoffeeClose from "../views/coffee-close/op-coffee-close";
import OpAddItemChallan from "../views/item-challan/op-item-challan";
import OpAddPurchaseEntry from "../views/purchase-entry/op-purchase-entry";

// Dynamically import your route components
const Index = lazy(() => import("../views/dashboard/index"));
const Attribute = lazy(() => import("../views/attributes/attribute"));
const AddAttribute = lazy(() => import("../views/attributes/add-attribute"));
const ViewAttribute = lazy(() => import("../views/attributes/view-attribute"));
const Role = lazy(() => import("../views/admin/role/role"));
const AddRole = lazy(() => import("../views/admin/role/add-role"));
const ViewRole = lazy(() => import("../views/admin/role/view-role"));
const User = lazy(() => import("../views/admin/user/user"));
const AddUser = lazy(() => import("../views/admin/user/add-user"));
// const ViewUser = lazy(() => import("../views/admin/user/view-user"));
// const RolePermissions = lazy(() => import("../views/roles/role-permissions"));
// const CreateRole = lazy(() => import("../views/roles/create-role"));
// const AssignPermissions = lazy(() => import("../views/roles/assign-permissions"));
// const AssignRole = lazy(() => import("../views/users/assign-role"));
const ViewCompany = lazy(() => import("../views/company/view-company"));
const AddCompany = lazy(() => import("../views/company/add-company"));
const Branch = lazy(() => import("../views/branch/branch"));
const AddBranch = lazy(() => import("../views/branch/add-branch"));
const ViewBranch = lazy(() => import("../views/branch/view-branch"));
const Department = lazy(() => import("../views/department/department"));
const Vendor = lazy(() => import("../views/vendor/vendor"));
const AddVendor = lazy(() => import("../views/vendor/add-vendor"));
const ViewVendor = lazy(() => import("../views/vendor/view-vendor"));
const RawMaterial = lazy(() => import("../views/raw-material/raw-material"));
const Menu = lazy(() => import("../views/menu/menu"));
const AddMenu = lazy(() => import("../views/menu/add-menu"));
const ViewMenu = lazy(() => import("../views/menu/view-menu"));
const Package = lazy(() => import("../views/package/package"));
const AddPackage = lazy(() => import("../views/package/add-package"));
const ViewPackage = lazy(() => import("../views/package/view-package"));
const Approval = lazy(() => import("../views/approval/approval"));
const AddApproval = lazy(() => import("../views/approval/add-approval"));
const ViewApproval = lazy(() => import("../views/approval/view-approval"));

const PurchaseEntry = lazy(() => import("../views/purchase-entry/purchase-entry"));
const AddPurchaseEntry = lazy(() => import("../views/purchase-entry/add-purchase-entry"));
const ViewPurchaseEntry = lazy(() => import("../views/purchase-entry/view-purchase-entry"));
const PurchaseEntryApproval = lazy(() => import("../views/purchase-entry-approval/purchase-entry-approval"));
const ViewPurchaseEntryApproval = lazy(() => import("../views/purchase-entry-approval/view-purchase-entry-approval"));

const PurchaseReturn = lazy(() => import("../views/purchase-return/purchase-return"));
const AddPurchaseReturn = lazy(() => import("../views/purchase-return/add-purchase-return"));
const ViewPurchaseReturn = lazy(() => import("../views/purchase-return/view-purchase-return"));

const Warehouse = lazy(() => import("../views/warehouse/warehouse"));
const FiscalYear = lazy(() => import("../views/fiscal-year/fiscal-year"));
const ItemChallan = lazy(() => import("../views/item-challan/item-challan"));
const AddItemChallan = lazy(() => import("../views/item-challan/add-item-challan"));
const ViewItemChallan = lazy(() => import("../views/item-challan/view-item-challan"));
const WarehouseClose = lazy(() => import("../views/warehouse-close/warehouse-close"));
const AddWarehouseClose = lazy(() => import("../views/warehouse-close/add-warehouse-close"));
const ViewWarehouseClose = lazy(() => import("../views/warehouse-close/view-warehouse-close"));

const OperationDashboard = lazy(() => import("../views/operation-dashboard/operation-dashboard"));
const UploadDoc = lazy(() => import("../views/operation-dashboard/upload-doc"));

const StockClose = lazy(() => import("../views/stock-close/stock-close"));
const AddStockClose = lazy(() => import("../views/stock-close/add-stock-close"));
const ViewStockClose = lazy(() => import("../views/stock-close/view-stock-close"));

const StsaleClose = lazy(() => import("../views/stsale-close/stsale-close"));
const AddStsaleClose = lazy(() => import("../views/stsale-close/add-stsale-close"));
const ViewStsaleClose = lazy(() => import("../views/stsale-close/view-stsale-close"));

const CoffeeClose = lazy(() => import("../views/coffee-close/coffee-close"));
const AddCoffeeClose = lazy(() => import("../views/coffee-close/add-coffee-close"));
const ViewCoffeeClose = lazy(() => import("../views/coffee-close/view-coffee-close"));

const PopcornYield = lazy(() => import("../views/popcorn-yield/popcorn-yield"));
const AddPopcornYield = lazy(() => import("../views/popcorn-yield/add-popcorn-yield"));
const ViewPopcornYield = lazy(() => import("../views/popcorn-yield/view-popcorn-yield"));

const StockMistake = lazy(() => import("../views/stock-mistake/stock-mistake"));
const AddStockMistake = lazy(() => import("../views/stock-mistake/add-stock-mistake"));
const ViewStockMistake = lazy(() => import("../views/stock-mistake/view-stock-mistake"));

const ShortPunch = lazy(() => import("../views/short-punch/short-punch"));
const AddShortPunch = lazy(() => import("../views/short-punch/add-short-punch"));
const ViewShortPunch = lazy(() => import("../views/short-punch/view-short-punch"));

const ShiftSummary = lazy(() => import("../views/operation-dashboard/shift-summary"));
const ReconSummary = lazy(() => import("../views/operation-dashboard/recon-summary"));
const UserSalesSummary = lazy(() => import("../views/operation-dashboard/user-sales-summary"));
const DaySummary = lazy(() => import("../views/operation-dashboard/day-summary"));
const MonthlySummary = lazy(() => import("../views/operation-dashboard/monthly-summary"));
const ControlSummary = lazy(() => import("../views/operation-dashboard/control-summary"));
const MistakeSummary = lazy(() => import("../views/operation-dashboard/mistake-summary"));
const PopcornYieldSummary = lazy(() => import("../views/operation-dashboard/popcorn-yield-summary"));
const ShortPunchSummary = lazy(() => import("../views/operation-dashboard/short-punch-summary"));
const ViewShortPunchSummary = lazy(() => import("../views/operation-dashboard/view-short-punch-summary"));
const OperationReportDashboard = lazy(() => import("../views/operation-dashboard/operation-report-dashboard"));

const HumanResourceDashboard = lazy(() => import("../views/human-resource-dashboard/human-resource-dashboard"));
const HrVendor = lazy(() => import("../views/human-resource/vendor/vendor"));
const AddHrVendor = lazy(() => import("../views/human-resource/vendor/add-vendor"));
const ViewHrVendor = lazy(() => import("../views/human-resource/vendor/view-vendor"));

const HrRawMaterial = lazy(() => import("../views/human-resource/raw-material/raw-material"));
const AddHrRawMaterial = lazy(() => import("../views/human-resource/raw-material/add-raw-material"));
const ViewHrRawMaterial = lazy(() => import("../views/human-resource/raw-material/view-raw-material"));

const HrPurchaseEntry = lazy(() => import("../views/human-resource/purchase-entry/purchase-entry"));
const AddHrPurchaseEntry = lazy(() => import("../views/human-resource/purchase-entry/add-purchase-entry"));
const ViewHrPurchaseEntry = lazy(() => import("../views/human-resource/purchase-entry/view-purchase-entry"));

const HrItemTransfer = lazy(() => import("../views/human-resource/item-transfer/item-transfer"));
const AddHrItemTransfer = lazy(() => import("../views/human-resource/item-transfer/add-item-transfer"));
const ViewHrItemTransfer = lazy(() => import("../views/human-resource/item-transfer/view-item-transfer"));

const HrWarehouseClose = lazy(() => import("../views/human-resource/warehouse-close/warehouse-close"));
const AddHrWarehouseClose = lazy(() => import("../views/human-resource/warehouse-close/add-warehouse-close"));
const ViewHrWarehouseClose = lazy(() => import("../views/human-resource/warehouse-close/view-warehouse-close"));

const InformationTechnologyDashboard = lazy(() => import("../views/information-technology-dashboard/information-technology-dashboard"));
const ItVendor = lazy(() => import("../views/information-technology/vendor/vendor"));
const AddItVendor = lazy(() => import("../views/information-technology/vendor/add-vendor"));
const ViewItVendor = lazy(() => import("../views/information-technology/vendor/view-vendor"));

const ItRawMaterial = lazy(() => import("../views/information-technology/raw-material/raw-material"));
const AddItRawMaterial = lazy(() => import("../views/information-technology/raw-material/add-raw-material"));
const ViewItRawMaterial = lazy(() => import("../views/information-technology/raw-material/view-raw-material"));

const ItPurchaseEntry = lazy(() => import("../views/information-technology/purchase-entry/purchase-entry"));
const AddItPurchaseEntry = lazy(() => import("../views/information-technology/purchase-entry/add-purchase-entry"));
const ViewItPurchaseEntry = lazy(() => import("../views/information-technology/purchase-entry/view-purchase-entry"));

const ItItemTransfer = lazy(() => import("../views/information-technology/item-transfer/item-transfer"));
const AddItItemTransfer = lazy(() => import("../views/information-technology/item-transfer/add-item-transfer"));
const ViewItItemTransfer = lazy(() => import("../views/information-technology/item-transfer/view-item-transfer"));

const ItWarehouseClose = lazy(() => import("../views/information-technology/warehouse-close/warehouse-close"));
const AddItWarehouseClose = lazy(() => import("../views/information-technology/warehouse-close/add-warehouse-close"));
const ViewItWarehouseClose = lazy(() => import("../views/information-technology/warehouse-close/view-warehouse-close"));

const MaintainenceDashboard = lazy(() => import("../views/maintainence-dashboard/maintainence-dashboard"));
const MnVendor = lazy(() => import("../views/maintainence/vendor/vendor"));
const AddMnVendor = lazy(() => import("../views/maintainence/vendor/add-vendor"));
const ViewMnVendor = lazy(() => import("../views/maintainence/vendor/view-vendor"));

const MnRawMaterial = lazy(() => import("../views/maintainence/raw-material/raw-material"));
const AddMnRawMaterial = lazy(() => import("../views/maintainence/raw-material/add-raw-material"));
const ViewMnRawMaterial = lazy(() => import("../views/maintainence/raw-material/view-raw-material"));

const MnPurchaseEntry = lazy(() => import("../views/maintainence/purchase-entry/purchase-entry"));
const AddMnPurchaseEntry = lazy(() => import("../views/maintainence/purchase-entry/add-purchase-entry"));
const ViewMnPurchaseEntry = lazy(() => import("../views/maintainence/purchase-entry/view-purchase-entry"));

const MnItemTransfer = lazy(() => import("../views/maintainence/item-transfer/item-transfer"));
const AddMnItemTransfer = lazy(() => import("../views/maintainence/item-transfer/add-item-transfer"));
const ViewMnItemTransfer = lazy(() => import("../views/maintainence/item-transfer/view-item-transfer"));

const MnWarehouseClose = lazy(() => import("../views/maintainence/warehouse-close/warehouse-close"));
const AddMnWarehouseClose = lazy(() => import("../views/maintainence/warehouse-close/add-warehouse-close"));
const ViewMnWarehouseClose = lazy(() => import("../views/maintainence/warehouse-close/view-warehouse-close"));

const Employee = lazy(() => import("../views/employee/employee"));
const AddEmployee = lazy(() => import("../views/employee/add-employee"));
const EditEmployee = lazy(() => import("../views/employee/edit-employee/edit-employee"));
const ViewEmployee = lazy(() => import("../views/employee/view-employee"));
const TicketStatus = lazy(() => import("../views/employee/ticket-status"));
const EmployeeUsername = lazy(() => import("../views/employee-username/employee-username"));

const RefundTicket = lazy(() => import("../views/refund-ticket/refund-ticket"));
const ViewRefundTicket = lazy(() => import("../views/refund-ticket/view-refund-ticket"));
const AddRefundTicket = lazy(() => import("../views/refund-ticket/add-refund-ticket"));
const ApproveRefundTicket = lazy(() => import("../views/refund-ticket-approval/refund-ticket-approval"));
const ViewApproveRefundTicket = lazy(() => import("../views/refund-ticket-approval/view-refund-ticket-approval"));

const CompTicket = lazy(() => import("../views/comp-ticket/comp-ticket"));
const ViewCompTicket = lazy(() => import("../views/comp-ticket/view-comp-ticket"));
const AddCompTicket = lazy(() => import("../views/comp-ticket/add-comp-ticket"));
const ApproveCompTicket = lazy(() => import("../views/comp-ticket-approval/comp-ticket-approval"));
const ViewApproveCompTicket = lazy(() => import("../views/comp-ticket-approval/view-comp-ticket-approval"));

const ViewProfileCompTicket = lazy(() => import("../views/profile/view-profile-comp-ticket"));
const AddProfileCompTicket = lazy(() => import("../views/profile/add-profile-comp-ticket"));

const UserProfile = lazy(() => import("../views/profile/profile"));

const Trailer = lazy(() => import("../views/trailer/trailer"));
const AddTrailer = lazy(() => import("../views/trailer/add-trailer"));
const ViewTrailer = lazy(() => import("../views/trailer/view-trailer"));

const TrikonAd = lazy(() => import("../views/trikon-ad/trikon-ad"));
const AddTrikonAd = lazy(() => import("../views/trikon-ad/add-trikon-ad"));
const EditTrikonAd = lazy(() => import("../views/trikon-ad/edit-trikon-ad"));
const ViewTrikonAd = lazy(() => import("../views/trikon-ad/view-trikon-ad"));

const MiscAd = lazy(() => import("../views/misc-ad/misc-ad"));
const AddMiscAd = lazy(() => import("../views/misc-ad/add-misc-ad"));
const EditMiscAd = lazy(() => import("../views/misc-ad/edit-misc-ad"));
const ViewMiscAd = lazy(() => import("../views/misc-ad/view-misc-ad"));

const ProjectionDashboard = lazy(() => import("../views/projection-dashboard/projection-dashboard"));
const ProjectionReportDashboard = lazy(() => import("../views/projection-dashboard/report-dashboard"));
const TrailerPlanning = lazy(() => import("../views/projection-dashboard/trailer-planning"));

const BannerSpace = lazy(() => import("../views/banner-space/banner-space"));
const AddBannerSpace = lazy(() => import("../views/banner-space/add-banner-space"));
const ViewBannerSpace = lazy(() => import("../views/banner-space/view-banner-space"));

const Banner = lazy(() => import("../views/banner/banner"));
const AddBanner = lazy(() => import("../views/banner/add-banner"));
const ViewBanner = lazy(() => import("../views/banner/view-banner"));
const BannerSummary = lazy(() => import("../views/projection-dashboard/banner-summary"));

const Lamp = lazy(() => import("../views/lamp/lamp"));
const AddLamp = lazy(() => import("../views/lamp/add-lamp"));
const ViewLamp = lazy(() => import("../views/lamp/view-lamp"));

const LampAssign = lazy(() => import("../views/lamp-assign/lamp-assign"));
const AddLampAssign = lazy(() => import("../views/lamp-assign/add-lamp-assign"));
const EditLampAssign = lazy(() => import("../views/lamp-assign/edit-lamp-assign"));
const ReplaceLampAssign = lazy(() => import("../views/lamp-assign/replace-lamp-assign"));
const ViewLampAssign = lazy(() => import("../views/lamp-assign/view-lamp-assign"));
// const BannerSummary = lazy(() => import("../views/projection-dashboard/banner-summary"));

const LampDaily = lazy(() => import("../views/lamp-daily/lamp-daily"));
const AddLampDaily = lazy(() => import("../views/lamp-daily/add-lamp-daily"));
const EditLampDaily = lazy(() => import("../views/lamp-daily/edit-lamp-daily"));
const ViewLampDaily = lazy(() => import("../views/lamp-daily/view-lamp-daily"));
const LampSummary = lazy(() => import("../views/projection-dashboard/lamp-summary"));
const LampDailySummary = lazy(() => import("../views/projection-dashboard/lamp-daily-summary"));

const EventType = lazy(() => import("../views/event-type/event-type"));
const AddEventType = lazy(() => import("../views/event-type/add-event-type"));
const ViewEventType = lazy(() => import("../views/event-type/view-event-type"));

const Event = lazy(() => import("../views/event/event"));
const AddEvent = lazy(() => import("../views/event/add-event"));
const ViewEvent = lazy(() => import("../views/event/view-event"));

const Movie = lazy(() => import("../views/movie/movie"));
const AddMovie = lazy(() => import("../views/movie/add-movie"));
const ViewMovie = lazy(() => import("../views/movie/view-movie"));

const MovieSchedule = lazy(() => import("../views/movie-schedule/movie-schedule"));
const AddMovieSchedule = lazy(() => import("../views/movie-schedule/add-movie-schedule"));
const ViewMovieSchedule = lazy(() => import("../views/movie-schedule/view-movie-schedule"));

const DefaultRouter = () => {
  const navigate = useNavigate();
  if (!localStorage.getItem('token')) {
    navigate("/");
  }

  const { permissions } = usePermissionContext();

  return (
    <Suspense fallback={<Loading />}>
      <Routes>
        <Route index element={<Index />} />
        <Route path="dashboard" element={<Index />} />

        {/* <Route path="create-user" element={<CreateUser />} />
        <Route path="role-permissions" element={<RolePermissions />} />
        <Route path="create-role" element={<CreateRole />} />
        <Route path="assign-permission" element={<AssignPermissions />} />
        <Route path="assign-role" element={<AssignRole />} /> */}
        {/* Role Routes */}
        {permissions.some(permission => permission.name === 'View Role') &&
          (<Route path="role" element={<Role />} />)
        }
        {permissions.some(permission => permission.name === 'Create Role') &&
          (<Route path="role/add" element={<AddRole />} />)
        }
        {permissions.some(permission => permission.name === 'Update Role') &&
          (<Route path="role/edit/:id" element={<AddRole />} />)
        }
        {permissions.some(permission => permission.name === 'View Role') &&
          (<Route path="role/view/:id" element={<ViewRole />} />)
        }

        {/* User routes */}
        {permissions.some(permission => permission.name === 'View User') &&
          (<Route path="user" element={<User />} />)
        }
        {permissions.some(permission => permission.name === 'Create User') &&
          (<Route path="user/add" element={<AddUser />} />)
        }
        {permissions.some(permission => permission.name === 'Update User') &&
          (<Route path="user/edit/:id" element={<AddUser />} />)
        }

        {/* Attribute Routes */}
        {permissions.some(permission => permission.name === 'View Attribute') && (
          <Route path="attribute" element={<Attribute />} />
        )}
        {permissions.some(permission => permission.name === 'Create Attribute') && (
          <Route path="attribute/add" element={<AddAttribute />} />
        )}
        {permissions.some(permission => permission.name === 'Update Attribute') && (
          <Route path="attribute/edit/:id" element={<AddAttribute />} />
        )}
        {permissions.some(permission => permission.name === 'View Attribute') && (
          <Route path="attribute/view/:id" element={<ViewAttribute />} />
        )}

        {/* Company Routes */}
        {permissions.some(permission => permission.name === 'View Company') && (
          <Route path="attribute/add" element={<AddAttribute />} />
        )}
        {permissions.some(permission => permission.name === 'Update Attribute') && (
          <Route path="attribute/edit/:id" element={<AddAttribute />} />
        )}
        {permissions.some(permission => permission.name === 'View Attribute') && (
          <Route path="attribute/view/:id" element={<ViewAttribute />} />
        )}

        {permissions.some(permission => permission.name === 'View Company') && (
          <Route path="company" element={<ViewCompany />} />
        )}
        {permissions.some(permission => permission.name === 'Create Company') && (
          <Route path="company/add" element={<AddCompany />} />
        )}
        {permissions.some(permission => permission.name === 'Update Company') && (
          <Route path="company/edit/:id" element={<AddCompany />} />
        )}

        {permissions.some(permission => permission.name === 'View Branch') && (
          <Route path="branch" element={<Branch />} />
        )}
        {permissions.some(permission => permission.name === 'Create Branch') && (
          <Route path="branch/add" element={<AddBranch />} />
        )}
        {permissions.some(permission => permission.name === 'Update Branch') && (
          <Route path="branch/edit/:id" element={<AddBranch />} />
        )}
        {permissions.some(permission => permission.name === 'View Branch') && (
          <Route path="branch/view/:id" element={<ViewBranch />} />
        )}

        {permissions.some(permission => permission.name === 'View Department') && (
          <Route path="department" element={<Department />} />
        )}

        {permissions.some(permission => permission.name === 'View Vendor') && (
          <Route path="vendor" element={<Vendor />} />
        )}
        {permissions.some(permission => permission.name === 'Create Vendor') && (
          <Route path="vendor/add" element={<AddVendor />} />
        )}
        {permissions.some(permission => permission.name === 'Update Vendor') && (
          <Route path="vendor/edit/:id" element={<AddVendor />} />
        )}
        {permissions.some(permission => permission.name === 'View Vendor') && (
          <Route path="vendor/view/:id" element={<ViewVendor />} />
        )}

        {permissions.some(permission => permission.name === 'View Raw Material') && (
          <Route path="raw_material" element={<RawMaterial />} />
        )}

        {permissions.some(permission => permission.name === 'View Menu') && (
          <Route path="menu" element={<Menu />} />
        )}
        {permissions.some(permission => permission.name === 'Create Menu') && (
          <Route path="menu/add" element={<AddMenu />} />
        )}
        {permissions.some(permission => permission.name === 'Update Menu') && (
          <Route path="menu/edit/:id" element={<AddMenu />} />
        )}
        {permissions.some(permission => permission.name === 'View Menu') && (
          <Route path="menu/view/:id" element={<ViewMenu />} />
        )}

        {permissions.some(permission => permission.name === 'View Package') && (
          <Route path="package" element={<Package />} />
        )}
        {permissions.some(permission => permission.name === 'Create Package') && (
          <Route path="package/add" element={<AddPackage />} />
        )}
        {permissions.some(permission => permission.name === 'Update Package') && (
          <Route path="package/edit/:id" element={<AddPackage />} />
        )}
        {permissions.some(permission => permission.name === 'View Package') && (
          <Route path="package/view/:id" element={<ViewPackage />} />
        )}

        {permissions.some(permission => permission.name === 'View Approval') && (
          <Route path="approval" element={<Approval />} />
        )}
        {permissions.some(permission => permission.name === 'Create Approval') && (
          <Route path="approval/add" element={<AddApproval />} />
        )}
        {permissions.some(permission => permission.name === 'Update Approval') && (
          <Route path="approval/edit/:id" element={<AddApproval />} />
        )}
        {permissions.some(permission => permission.name === 'View Approval') && (
          <Route path="approval/view/:id" element={<ViewApproval />} />
        )}

        {permissions.some(permission => permission.name === 'View Purchase Entry') && (
          <Route path="purchase_entry" element={<PurchaseEntry />} />
        )}

        {permissions.some(permission => permission.name === 'Create Purchase Entry') && (
          <Route path="purchase_entry/add" element={<AddPurchaseEntry />} />
        )}

        {permissions.some(permission => permission.name === 'Create Purchase Entry') && (
          <Route path='store/purchase_entry/add' element={<OpAddPurchaseEntry />} />
        )}

        {permissions.some(permission => permission.name === 'Update Purchase Entry') && (
          <Route path="purchase_entry/edit/:id" element={<AddPurchaseEntry />} />
        )}
        {permissions.some(permission => permission.name === 'View Purchase Entry') && (
          <Route path="purchase_entry/view/:id" element={<ViewPurchaseEntry />} />
        )}

        {permissions.some(permission => permission.name === 'Approve Purchase Entry') && (
          <Route path="purchase_entry_approval" element={<PurchaseEntryApproval />} />
        )}
        {permissions.some(permission => permission.name === 'Approve Purchase Entry') && (
          <Route path="purchase_entry_approval/view/:id" element={<ViewPurchaseEntryApproval />} />
        )}
        {permissions.some(permission => permission.name === 'View Purchase Return') && (
          <Route path="purchase_return" element={<PurchaseReturn />} />
        )}
        {permissions.some(permission => permission.name === 'Create Purchase Return') && (
          <Route path="purchase_return/add" element={<AddPurchaseReturn />} />
        )}
        {permissions.some(permission => permission.name === 'Update Purchase Return') && (
          <Route path="purchase_return/edit/:id" element={<AddPurchaseReturn />} />
        )}
        {permissions.some(permission => permission.name === 'View Purchase Return') && (
          <Route path="purchase_return/view/:id" element={<ViewPurchaseReturn />} />
        )}
        {permissions.some(permission => permission.name === 'Purchase Return') && (
          <Route path="purchase_return_approval" element={<PurchaseReturnApproval />} />
        )}
        {permissions.some(permission => permission.name === 'Approve Purchase Return')}
        <Route path="purchase_return_approval/view/:id" element={<ViewPurchaseReturnApproval />} />

        <Route path="bill_mapping" element={<BillMapping />} />
        {/* <Route path="bill_mapping/" element={<BillMapping />} /> */}

        {permissions.some(permission => permission.name === 'View Mistake Ticket') && (
          <Route path="mistake_ticket" element={<MistakeTicket />} />
        )}
        {permissions.some(permission => permission.name === 'Create Mistake Ticket') && (
          <Route path="mistake_ticket/add" element={<AddMistakeTicket />} />
        )}
        {permissions.some(permission => permission.name === 'Update Mistake Ticket') && (
          <Route path="mistake_ticket/edit/:id" element={<AddMistakeTicket />} />
        )}
        {permissions.some(permission => permission.name === 'View Mistake Ticket') && (
          <Route path="mistake_ticket/view/:id" element={<ViewMistakeTicket />} />
        )}

        {permissions.some(permission => permission.name === 'View Warehouse') && (
          <Route path='warehouse' element={<Warehouse />} />
        )}

        {permissions.some(permission => permission.name === 'View Fiscal Year') && (
          <Route path='fiscal_year' element={<FiscalYear />} />
        )}

        {permissions.some(permission => permission.name === 'View Item Challan') && (
          <Route path='transfer' element={<ItemChallan />} />
        )}

        {permissions.some(permission => permission.name === 'Create Item Challan') && (
          <Route path='transfer/:type/add' element={<AddItemChallan />} />
        )}

        {permissions.some(permission => permission.name === 'Create Item Challan') && (
          <Route path='operation/transfer/:type/add' element={<OpAddItemChallan />} />
        )}

        {permissions.some(permission => permission.name === 'Update Item Challan') && (
          <Route path='transfer/:type/edit/:id' element={<AddItemChallan />} />
        )}
        {permissions.some(permission => permission.name === 'View Item Challan') && (
          <Route path='transfer/:type/view/:id' element={<ViewItemChallan />} />
        )}

        {permissions.some(permission => permission.name === 'View Warehouse Close') && (
          <Route path='warehouse_close' element={<WarehouseClose />} />
        )}
        {permissions.some(permission => permission.name === 'Create Warehouse Close') && (
          <Route path='warehouse_close/add' element={<AddWarehouseClose />} />
        )}
        {permissions.some(permission => permission.name === 'Update Warehouse Close') && (
          <Route path='warehouse_close/edit/:id' element={<AddWarehouseClose />} />
        )}
        {permissions.some(permission => permission.name === 'View Warehouse Close') && (
          <Route path='warehouse_close/view/:id' element={<ViewWarehouseClose />} />
        )}

        {permissions.some(permission => permission.name === 'Operation Dashboard') && (
          <Route path='operation/dashboard' element={<OperationDashboard />} />
        )}
        {(permissions.some(permission => permission.name === 'Upload Mor Close') ||
          permissions.some(permission => permission.name === 'Upload Even Close')) && (
            <Route path='operation/dashboard/:type/docs' element={<UploadDoc />} />
          )}

        {(permissions.some(permission => permission.name === 'View Mor Close') ||
          permissions.some(permission => permission.name === 'View Even Close'))
          && (
            <Route path='stock_close/:type' element={<StockClose />} />
          )}

        {(permissions.some(permission => permission.name === 'Create Mor Close') ||
          permissions.some(permission => permission.name === 'Create Even Close'))
          && (
            <Route path='stock_close/:type/add' element={<AddStockClose />} />
          )}

        {(permissions.some(permission => permission.name === 'Create Mor Close') ||
          permissions.some(permission => permission.name === 'Create Even Close'))
          && (
            <Route path='operation/stock_close/:type' element={<OpAddStockClose />} />
          )}

        {(permissions.some(permission => permission.name === 'Update Mor Close') ||
          permissions.some(permission => permission.name === 'Update Even Close'))
          && (
            <Route path='stock_close/:type/edit/:id' element={<AddStockClose />} />
          )}

        {(permissions.some(permission => permission.name === 'View Mor Close') ||
          permissions.some(permission => permission.name === 'View Even Close'))
          && (
            <Route path='stock_close/:type/view/:id' element={<ViewStockClose />} />
          )}

        {(permissions.some(permission => permission.name === 'View Mor Stsale') ||
          permissions.some(permission => permission.name === 'View Even Stsale'))
          && (
            <Route path='stsale_close/:type' element={<StsaleClose />} />
          )}

        {(permissions.some(permission => permission.name === 'Create Mor Stsale') ||
          permissions.some(permission => permission.name === 'Create Even Stsale'))
          && (
            <Route path='stsale_close/:type/add' element={<AddStsaleClose />} />
          )}

        {(permissions.some(permission => permission.name === 'Create Mor Stsale') ||
          permissions.some(permission => permission.name === 'Create Even Stsale'))
          && (
            <Route path='operation/stsale_close/:type' element={<OpAddStsaleClose />} />
          )}

        {(permissions.some(permission => permission.name === 'Update Mor Stsale') ||
          permissions.some(permission => permission.name === 'Update Even Stsale'))
          && (
            <Route path='stsale_close/:type/edit/:id' element={<AddStsaleClose />} />
          )}
        {(permissions.some(permission => permission.name === 'View Mor Stsale') ||
          permissions.some(permission => permission.name === 'View Even Stsale'))
          && (
            <Route path='stsale_close/:type/view/:id' element={<ViewStsaleClose />} />
          )}

        {(permissions.some(permission => permission.name === 'View Mor Coffee Close') ||
          permissions.some(permission => permission.name === 'View Even Coffee Close'))
          && (
            <Route path='coffee_close/:type' element={<CoffeeClose />} />
          )}

        {(permissions.some(permission => permission.name === 'Create Mor Coffee Close') ||
          permissions.some(permission => permission.name === 'Create Even Coffee Close'))
          && (
            <Route path='coffee_close/:type/add' element={<AddCoffeeClose />} />
          )}

        {(permissions.some(permission => permission.name === 'Create Mor Coffee Close') ||
          permissions.some(permission => permission.name === 'Create Even Coffee Close'))
          && (
            <Route path='operation/coffee_close/:type' element={<OpAddCoffeeClose />} />
          )}

        {(permissions.some(permission => permission.name === 'Update Mor Coffee Close') ||
          permissions.some(permission => permission.name === 'Update Even Coffee Close'))
          && (
            <Route path='coffee_close/:type/edit/:id' element={<AddCoffeeClose />} />
          )}
        {(permissions.some(permission => permission.name === 'View Mor Coffee Close') ||
          permissions.some(permission => permission.name === 'View Even Coffee Close'))
          && (
            <Route path='coffee_close/:type/view/:id' element={<ViewCoffeeClose />} />
          )}

        {permissions.some(permission => permission.name === 'View Popcorn Yield') && (
          <Route path='popcorn_yield' element={<PopcornYield />} />
        )}
        {permissions.some(permission => permission.name === 'Create Popcorn Yield') && (
          <Route path='popcorn_yield/add' element={<AddPopcornYield />} />
        )}
        {permissions.some(permission => permission.name === 'Update Popcorn Yield') && (
          <Route path='popcorn_yield/edit/:id' element={<AddPopcornYield />} />
        )}
        {permissions.some(permission => permission.name === 'View Popcorn Yield') && (
          <Route path='popcorn_yield/view/:id' element={<ViewPopcornYield />} />
        )}

        {permissions.some(permission => permission.name === 'View Stock Mistake') && (
          <Route path='stock_mistake' element={<StockMistake />} />
        )}
        {permissions.some(permission => permission.name === 'Create Stock Mistake') && (
          <Route path='stock_mistake/add' element={<AddStockMistake />} />
        )}
        {permissions.some(permission => permission.name === 'Update Stock Mistake') && (
          <Route path='stock_mistake/edit/:id' element={<AddStockMistake />} />
        )}
        {permissions.some(permission => permission.name === 'View Stock Mistake') && (
          <Route path='stock_mistake/view/:id' element={<ViewStockMistake />} />
        )}

        {permissions.some(permission => permission.name === 'View Short Punch') && (
          <Route path='short_punch' element={<ShortPunch />} />
        )}
        {permissions.some(permission => permission.name === 'Create Short Punch') && (
          <Route path='short_punch/add' element={<AddShortPunch />} />
        )}
        {permissions.some(permission => permission.name === 'Update Short Punch') && (
          <Route path='short_punch/edit/:id' element={<AddShortPunch />} />
        )}
        {permissions.some(permission => permission.name === 'View Short Punch') && (
          <Route path='short_punch/view/:id' element={<ViewShortPunch />} />
        )}

        {permissions.some(permission => permission.name === 'Shift Summary') && (
          <Route path='operation/report/shift_summary' element={<ShiftSummary />} />
        )}
        {permissions.some(permission => permission.name === 'Recon Summary') && (
          <Route path='operation/report/recon_summary' element={<ReconSummary />} />
        )}
        {permissions.some(permission => permission.name === 'User Wise Sale') && (
          <Route path='operation/report/user_sales' element={<UserSalesSummary />} />
        )}
        {permissions.some(permission => permission.name === 'Day Summary') && (
          <Route path='operation/report/day_summary' element={<DaySummary />} />
        )}
        {permissions.some(permission => permission.name === 'Monthly Summary') && (
          <Route path='operation/report/monthly_summary' element={<MonthlySummary />} />
        )}
        {permissions.some(permission => permission.name === 'Control Summary') && (
          <Route path='operation/report/control_summary' element={<ControlSummary />} />
        )}
        {permissions.some(permission => permission.name === 'Stock Mistake Summary') && (
          <Route path='operation/report/mistake_summary' element={<MistakeSummary />} />
        )}
        {permissions.some(permission => permission.name === 'Popcorn Summary') && (
          <Route path='operation/report/popcorn_yield_summary' element={<PopcornYieldSummary />} />
        )}
        {permissions.some(permission => permission.name === 'Short Punch Summary') && (
          <Route path='operation/report/short_punch_summary' element={<ShortPunchSummary />} />
        )}
        {permissions.some(permission => permission.name === 'Short Punch Summary') && (
          <Route path='operation/report/short_punch_summary/view/:id' element={<ViewShortPunchSummary />} />
        )}
        {permissions.some(permission => permission.name === 'Operation Report') && (
          <Route path='operation/report' element={<OperationReportDashboard />} />
        )}

        {permissions.some(permission => permission.name === 'View HR Dashboard') && (
          <Route path='hr' element={<HumanResourceDashboard />} />
        )}
        {permissions.some(permission => permission.name === 'View HR Vendor') && (
          <Route path='hr/vendor' element={<HrVendor />} />
        )}
        {permissions.some(permission => permission.name === 'Create HR Vendor') && (
          <Route path='hr/vendor/add' element={<AddHrVendor />} />
        )}
        {permissions.some(permission => permission.name === 'Update HR Vendor') && (
          <Route path='hr/vendor/edit/:id' element={<AddHrVendor />} />
        )}
        {permissions.some(permission => permission.name === 'View HR Vendor') && (
          <Route path='hr/vendor/view/:id' element={<ViewHrVendor />} />
        )}

        {permissions.some(permission => permission.name === 'View HR Raw Material') && (
          <Route path='hr/raw_material' element={<HrRawMaterial />} />
        )}
        {permissions.some(permission => permission.name === 'Create HR Raw Material') && (
          <Route path='hr/raw_material/add' element={<AddHrRawMaterial />} />
        )}
        {permissions.some(permission => permission.name === 'Update HR Raw Material') && (
          <Route path='hr/raw_material/edit/:id' element={<AddHrRawMaterial />} />
        )}
        {permissions.some(permission => permission.name === 'View HR Raw Material') && (
          <Route path='hr/raw_material/view/:id' element={<ViewHrRawMaterial />} />
        )}

        {permissions.some(permission => permission.name === 'View HR Purchase Entry') && (
          <Route path='hr/purchase_entry' element={<HrPurchaseEntry />} />
        )}
        {permissions.some(permission => permission.name === 'Create HR Purchase Entry') && (
          <Route path='hr/purchase_entry/add' element={<AddHrPurchaseEntry />} />
        )}
        {permissions.some(permission => permission.name === 'Update HR Purchase Entry') && (
          <Route path='hr/purchase_entry/edit/:id' element={<AddHrPurchaseEntry />} />
        )}
        {permissions.some(permission => permission.name === 'View HR Purchase Entry') && (
          <Route path='hr/purchase_entry/view/:id' element={<ViewHrPurchaseEntry />} />
        )}

        {permissions.some(permission => permission.name === 'View HR Item Transfer') && (
          <Route path='hr/transfer' element={<HrItemTransfer />} />
        )}
        {permissions.some(permission => permission.name === 'Create HR Item Transfer') && (
          <Route path='hr/transfer/:type/add' element={<AddHrItemTransfer />} />
        )}
        {permissions.some(permission => permission.name === 'Update HR Item Transfer') && (
          <Route path='hr/transfer/:type/edit/:id' element={<AddHrItemTransfer />} />
        )}
        {permissions.some(permission => permission.name === 'View HR Item Transfer') && (
          <Route path='hr/transfer/:type/view/:id' element={<ViewHrItemTransfer />} />
        )}

        {permissions.some(permission => permission.name === 'View HR Warehouse Close') && (
          <Route path='hr/warehouse_close' element={<HrWarehouseClose />} />
        )}
        {permissions.some(permission => permission.name === 'Create HR Warehouse Close') && (
          <Route path='hr/warehouse_close/add' element={<AddHrWarehouseClose />} />
        )}
        {permissions.some(permission => permission.name === 'Update HR Warehouse Close') && (
          <Route path='hr/warehouse_close/edit/:id' element={<AddHrWarehouseClose />} />
        )}
        {permissions.some(permission => permission.name === 'View HR Warehouse Close') && (
          <Route path='hr/warehouse_close/view/:id' element={<ViewHrWarehouseClose />} />
        )}

        {permissions.some(permission => permission.name === 'View IT Dashboard') && (
          <Route path='it' element={<InformationTechnologyDashboard />} />
        )}
        {permissions.some(permission => permission.name === 'View IT Vendor') && (
          <Route path='it/vendor' element={<ItVendor />} />
        )}
        {permissions.some(permission => permission.name === 'Create IT Vendor') && (
          <Route path='it/vendor/add' element={<AddItVendor />} />
        )}
        {permissions.some(permission => permission.name === 'Update IT Vendor') && (
          <Route path='it/vendor/edit/:id' element={<AddItVendor />} />
        )}
        {permissions.some(permission => permission.name === 'View IT Vendor') && (
          <Route path='it/vendor/view/:id' element={<ViewItVendor />} />
        )}

        {permissions.some(permission => permission.name === 'View IT Raw Material') && (
          <Route path='it/raw_material' element={<ItRawMaterial />} />
        )}
        {permissions.some(permission => permission.name === 'Create IT Raw Material') && (
          <Route path='it/raw_material/add' element={<AddItRawMaterial />} />
        )}
        {permissions.some(permission => permission.name === 'Update IT Raw Material') && (
          <Route path='it/raw_material/edit/:id' element={<AddItRawMaterial />} />
        )}
        {permissions.some(permission => permission.name === 'View IT Raw Material') && (
          <Route path='it/raw_material/view/:id' element={<ViewItRawMaterial />} />
        )}

        {permissions.some(permission => permission.name === 'View IT Purchase Entry') && (
          <Route path='it/purchase_entry' element={<ItPurchaseEntry />} />
        )}
        {permissions.some(permission => permission.name === 'Create IT Purchase Entry') && (
          <Route path='it/purchase_entry/add' element={<AddItPurchaseEntry />} />
        )}
        {permissions.some(permission => permission.name === 'Update IT Purchase Entry') && (
          <Route path='it/purchase_entry/edit/:id' element={<AddItPurchaseEntry />} />
        )}
        {permissions.some(permission => permission.name === 'View IT Purchase Entry') && (
          <Route path='it/purchase_entry/view/:id' element={<ViewItPurchaseEntry />} />
        )}

        {permissions.some(permission => permission.name === 'View IT Item Transfer') && (
          <Route path='it/transfer' element={<ItItemTransfer />} />
        )}
        {permissions.some(permission => permission.name === 'Create IT Item Transfer') && (
          <Route path='it/transfer/:type/add' element={<AddItItemTransfer />} />
        )}
        {permissions.some(permission => permission.name === 'Update IT Item Transfer') && (
          <Route path='it/transfer/:type/edit/:id' element={<AddItItemTransfer />} />
        )}
        {permissions.some(permission => permission.name === 'View IT Item Transfer') && (
          <Route path='it/transfer/:type/view/:id' element={<ViewItItemTransfer />} />
        )}

        {permissions.some(permission => permission.name === 'View IT Warehouse Close') && (
          <Route path='it/warehouse_close' element={<ItWarehouseClose />} />
        )}
        {permissions.some(permission => permission.name === 'Create IT Warehouse Close') && (
          <Route path='it/warehouse_close/add' element={<AddItWarehouseClose />} />
        )}
        {permissions.some(permission => permission.name === 'Update IT Warehouse Close') && (
          <Route path='it/warehouse_close/edit/:id' element={<AddItWarehouseClose />} />
        )}
        {permissions.some(permission => permission.name === 'View IT Warehouse Close') && (
          <Route path='it/warehouse_close/view/:id' element={<ViewItWarehouseClose />} />
        )}

        {permissions.some(permission => permission.name === 'View MN Dashboard') && (
          <Route path='mn' element={<MaintainenceDashboard />} />
        )}
        {permissions.some(permission => permission.name === 'View MN Vendor') && (
          <Route path='mn/vendor' element={<MnVendor />} />
        )}
        {permissions.some(permission => permission.name === 'Create MN Vendor') && (
          <Route path='mn/vendor/add' element={<AddMnVendor />} />
        )}
        {permissions.some(permission => permission.name === 'Update MN Vendor') && (
          <Route path='mn/vendor/edit/:id' element={<AddMnVendor />} />
        )}
        {permissions.some(permission => permission.name === 'View MN Vendor') && (
          <Route path='mn/vendor/view/:id' element={<ViewMnVendor />} />
        )}

        {permissions.some(permission => permission.name === 'View MN Raw Material') && (
          <Route path='mn/raw_material' element={<MnRawMaterial />} />
        )}
        {permissions.some(permission => permission.name === 'Create MN Raw Material') && (
          <Route path='mn/raw_material/add' element={<AddMnRawMaterial />} />
        )}
        {permissions.some(permission => permission.name === 'Update MN Raw Material') && (
          <Route path='mn/raw_material/edit/:id' element={<AddMnRawMaterial />} />
        )}
        {permissions.some(permission => permission.name === 'View MN Raw Material') && (
          <Route path='mn/raw_material/view/:id' element={<ViewMnRawMaterial />} />
        )}

        {permissions.some(permission => permission.name === 'View MN Purchase Entry') && (
          <Route path='mn/purchase_entry' element={<MnPurchaseEntry />} />
        )}
        {permissions.some(permission => permission.name === 'Create MN Purchase Entry') && (
          <Route path='mn/purchase_entry/add' element={<AddMnPurchaseEntry />} />
        )}
        {permissions.some(permission => permission.name === 'Update MN Purchase Entry') && (
          <Route path='mn/purchase_entry/edit/:id' element={<AddMnPurchaseEntry />} />
        )}
        {permissions.some(permission => permission.name === 'View MN Purchase Entry') && (
          <Route path='mn/purchase_entry/view/:id' element={<ViewMnPurchaseEntry />} />
        )}

        {permissions.some(permission => permission.name === 'View MN Item Transfer') && (
          <Route path='mn/transfer' element={<MnItemTransfer />} />
        )}
        {permissions.some(permission => permission.name === 'Create MN Item Transfer') && (
          <Route path='mn/transfer/:type/add' element={<AddMnItemTransfer />} />
        )}
        {permissions.some(permission => permission.name === 'Update MN Item Transfer') && (
          <Route path='mn/transfer/:type/edit/:id' element={<AddMnItemTransfer />} />
        )}
        {permissions.some(permission => permission.name === 'View MN Item Transfer') && (
          <Route path='mn/transfer/:type/view/:id' element={<ViewMnItemTransfer />} />
        )}

        {permissions.some(permission => permission.name === 'View MN Warehouse Close') && (
          <Route path='mn/warehouse_close' element={<MnWarehouseClose />} />
        )}
        {permissions.some(permission => permission.name === 'Create MN Warehouse Close') && (
          <Route path='mn/warehouse_close/add' element={<AddMnWarehouseClose />} />
        )}
        {permissions.some(permission => permission.name === 'Update MN Warehouse Close') && (
          <Route path='mn/warehouse_close/edit/:id' element={<AddMnWarehouseClose />} />
        )}
        {permissions.some(permission => permission.name === 'View MN Warehouse Close') && (
          <Route path='mn/warehouse_close/view/:id' element={<ViewMnWarehouseClose />} />
        )}

        {permissions.some(permission => permission.name === 'View Employee') && (
          <Route path='employee' element={<Employee />} />
        )}
        {permissions.some(permission => permission.name === 'Create Employee') && (
          <Route path='employee/add' element={<AddEmployee />} />
        )}
        {permissions.some(permission => permission.name === 'Update Employee') && (
          <Route path='employee/edit/:id' element={<EditEmployee />} />
        )}
        {permissions.some(permission => permission.name === 'View Employee') && (
          <Route path='employee/view/:id' element={<ViewEmployee />} />
        )}

        <Route path='employee/ticket' element={<TicketStatus />} />

        {permissions.some(permission => permission.name === 'View Employee Username') && (
          <Route path='employee/username' element={<EmployeeUsername />} />
        )}

        {/* store reports */}
        <Route path='upload' element={<Upload />} />

        <Route path='store/report' element={<StoreReportDashboard />} />
        <Route path='store/report/inventory' element={<InventorySummary />} />

        {permissions.some(permission => permission.name === 'View Refund Ticket') && (
          <Route path="refund_ticket" element={<RefundTicket />} />
        )}
        {permissions.some(permission => permission.name === 'Create Refund Ticket') && (
          <Route path="refund_ticket/add" element={<AddRefundTicket />} />
        )}
        {permissions.some(permission => permission.name === 'Update Refund Ticket') && (
          <Route path="refund_ticket/edit/:id" element={<AddRefundTicket />} />
        )}
        {permissions.some(permission => permission.name === 'View Refund Ticket') && (
          <Route path="refund_ticket/view/:id" element={<ViewRefundTicket />} />
        )}
        {permissions.some(permission => permission.name === 'View Refund Ticket') && (
          <Route path="refund_ticket/approval" element={<ApproveRefundTicket />} />
        )}
        {permissions.some(permission => permission.name === 'Approve Refund Ticket') && (
          <Route path="refund_ticket/approval/view/:id" element={<ViewApproveRefundTicket />} />
        )}

        {permissions.some(permission => permission.name === 'View Complimentary Ticket') && (
          <Route path="comp_ticket" element={<CompTicket />} />
        )}

        {permissions.some(permission => permission.name === 'Create Single Complimentary Ticket') && (
          <Route path="profile/comp_ticket/add" element={<AddProfileCompTicket />} />
        )}
        {permissions.some(permission => permission.name === 'Update Complimentary Ticket') && (
          <Route path="comp_ticket/edit/:id" element={<AddCompTicket />} />
        )}
        {permissions.some(permission => permission.name === 'View Complimentary Ticket') && (
          <Route path="comp_ticket/view/:id" element={<ViewCompTicket />} />
        )}
        {permissions.some(permission => permission.name === 'View Single Complimentary Ticket') && (
          <Route path="profile/comp_ticket/view/:id" element={<ViewProfileCompTicket />} />
        )}
        {permissions.some(permission => permission.name === 'Approve Complimentary Ticket') && (
          <Route path="comp_ticket/approval" element={<ApproveCompTicket />} />
        )}
        {permissions.some(permission => permission.name === 'Approve Complimentary Ticket') && (
          <Route path="comp_ticket/approval/view/:id" element={<ViewApproveCompTicket />} />
        )}

        <Route path="profile" element={<UserProfile />} />

        {permissions.some(permission => permission.name === 'View Trailer') && (
          <Route path="projection/trailer" element={<Trailer />} />
        )}
        {permissions.some(permission => permission.name === 'Create Trailer') && (
          <Route path="projection/trailer/add" element={<AddTrailer />} />
        )}
        {permissions.some(permission => permission.name === 'Update Trailer') && (
          <Route path="projection/trailer/edit/:id" element={<AddTrailer />} />
        )}
        {permissions.some(permission => permission.name === 'View Trailer') && (
          <Route path="projection/trailer/view/:id" element={<ViewTrailer />} />
        )}

        {permissions.some(permission => permission.name === 'View TrikonAd') && (
          <Route path="projection/trikon_ad" element={<TrikonAd />} />
        )}
        {permissions.some(permission => permission.name === 'Create TrikonAd') && (
          <Route path="projection/trikon_ad/add" element={<AddTrikonAd />} />
        )}
        {permissions.some(permission => permission.name === 'Update TrikonAd') && (
          <Route path="projection/trikon_ad/edit/:id" element={<EditTrikonAd />} />
        )}
        {permissions.some(permission => permission.name === 'View TrikonAd') && (
          <Route path="projection/trikon_ad/view/:id" element={<ViewTrikonAd />} />
        )}

        {permissions.some(permission => permission.name === 'View MiscAd') && (
          <Route path="projection/misc_ad" element={<MiscAd />} />
        )}
        {permissions.some(permission => permission.name === 'Create MiscAd') && (
          <Route path="projection/misc_ad/add" element={<AddMiscAd />} />
        )}
        {permissions.some(permission => permission.name === 'Update MiscAd') && (
          <Route path="projection/misc_ad/edit/:id" element={<EditMiscAd />} />
        )}
        {permissions.some(permission => permission.name === 'View MiscAd') && (
          <Route path="projection/misc_ad/view/:id" element={<ViewMiscAd />} />
        )}

        {permissions.some(permission => permission.name === 'Projection Report') && (
          <Route path="/projection/report" element={<ProjectionReportDashboard />} />
        )}

        {permissions.some(permission => permission.name === 'Projection Report') && (
          <Route path="/projection/dashboard" element={<ProjectionDashboard />} />
        )}

        {permissions.some(permission => permission.name === 'Trailer Planning') && (
          <Route path="/projection/report/trailer_planning" element={<TrailerPlanning />} />
        )}

        {permissions.some(permission => permission.name === 'View Banner Space') && (
          <Route path="projection/banner_space" element={<BannerSpace />} />
        )}
        {permissions.some(permission => permission.name === 'Create Banner Space') && (
          <Route path="projection/banner_space/add" element={<AddBannerSpace />} />
        )}
        {permissions.some(permission => permission.name === 'Update Banner Space') && (
          <Route path="projection/banner_space/edit/:id" element={<AddBannerSpace />} />
        )}
        {permissions.some(permission => permission.name === 'View Banner Space') && (
          <Route path="projection/banner_space/view/:id" element={<ViewBannerSpace />} />
        )}

        {permissions.some(permission => permission.name === 'View Banner') && (
          <Route path="projection/banner" element={<Banner />} />
        )}
        {permissions.some(permission => permission.name === 'Create Banner') && (
          <Route path="projection/banner/add" element={<AddBanner />} />
        )}
        {permissions.some(permission => permission.name === 'Update Banner') && (
          <Route path="projection/banner/edit/:id" element={<AddBanner />} />
        )}
        {permissions.some(permission => permission.name === 'View Banner') && (
          <Route path="projection/banner/view/:id" element={<ViewBanner />} />
        )}

        {permissions.some(permission => permission.name === 'Banner Summary') && (
          <Route path="/projection/report/banner_summary" element={<BannerSummary />} />
        )}

        {permissions.some(permission => permission.name === 'View Lamp') && (
          <Route path="projection/lamp" element={<Lamp />} />
        )}
        {permissions.some(permission => permission.name === 'Create Lamp') && (
          <Route path="projection/lamp/add" element={<AddLamp />} />
        )}
        {permissions.some(permission => permission.name === 'Update Lamp') && (
          <Route path="projection/lamp/edit/:id" element={<AddLamp />} />
        )}
        {permissions.some(permission => permission.name === 'View Lamp') && (
          <Route path="projection/lamp/view/:id" element={<ViewLamp />} />
        )}

        {permissions.some(permission => permission.name === 'View Lamp Assign') && (
          <Route path="projection/lamp_assign" element={<LampAssign />} />
        )}
        {permissions.some(permission => permission.name === 'Create Lamp Assign') && (
          <Route path="projection/lamp_assign/add" element={<AddLampAssign />} />
        )}
        {permissions.some(permission => permission.name === 'Update Lamp Assign') && (
          <Route path="projection/lamp_assign/edit/:id" element={<EditLampAssign />} />
        )}
        {permissions.some(permission => permission.name === 'View Lamp Assign') && (
          <Route path="projection/lamp_assign/replace/:id" element={<ReplaceLampAssign />} />
        )}
        {permissions.some(permission => permission.name === 'View Lamp Assign') && (
          <Route path="projection/lamp_assign/view/:id" element={<ViewLampAssign />} />
        )}

        {permissions.some(permission => permission.name === 'View Lamp Daily') && (
          <Route path="projection/lamp_daily" element={<LampDaily />} />
        )}
        {permissions.some(permission => permission.name === 'Create Lamp Daily') && (
          <Route path="projection/lamp_daily/add" element={<AddLampDaily />} />
        )}
        {permissions.some(permission => permission.name === 'Update Lamp Daily') && (
          <Route path="projection/lamp_daily/edit/:id" element={<EditLampDaily />} />
        )}
        {permissions.some(permission => permission.name === 'View Lamp Daily') && (
          <Route path="projection/lamp_daily/view/:id" element={<ViewLampDaily />} />
        )}
        {permissions.some(permission => permission.name === 'Lamp Summary') && (
          <Route path="projection/report/lamp_summary" element={<LampSummary />} />
        )}
        {permissions.some(permission => permission.name === 'Lamp Daily Summary') && (
          <Route path="projection/report/lamp_daily_summary" element={<LampDailySummary />} />
        )}

        {permissions.some(permission => permission.name === 'View Event Type') && (
          <Route path="projection/event_type" element={<EventType />} />
        )}
        {permissions.some(permission => permission.name === 'Create Event Type') && (
          <Route path="projection/event_type/add" element={<AddEventType />} />
        )}
        {permissions.some(permission => permission.name === 'Update Event Type') && (
          <Route path="projection/event_type/edit/:id" element={<AddEventType />} />
        )}
        {permissions.some(permission => permission.name === 'View Event Type') && (
          <Route path="projection/event_type/view/:id" element={<ViewEventType />} />
        )}

        {permissions.some(permission => permission.name === 'View Event') && (
          <Route path="projection/event" element={<Event />} />
        )}
        {permissions.some(permission => permission.name === 'Create Event') && (
          <Route path="projection/event/add" element={<AddEvent />} />
        )}
        {permissions.some(permission => permission.name === 'Update Event') && (
          <Route path="projection/event/edit/:id" element={<AddEvent />} />
        )}
        {permissions.some(permission => permission.name === 'View Event') && (
          <Route path="projection/event/view/:id" element={<ViewEvent />} />
        )}

        {permissions.some(permission => permission.name === 'View Movie') && (
          <Route path="projection/movie" element={<Movie />} />
        )}
        {permissions.some(permission => permission.name === 'Create Movie') && (
          <Route path="projection/movie/add" element={<AddMovie />} />
        )}
        {permissions.some(permission => permission.name === 'Update Movie') && (
          <Route path="projection/movie/edit/:id" element={<AddMovie />} />
        )}
        {permissions.some(permission => permission.name === 'View Movie') && (
          <Route path="projection/movie/view/:id" element={<ViewMovie />} />
        )}

        {permissions.some(permission => permission.name === 'View Movie Schedule') && (
          <Route path="projection/movie_schedule" element={<MovieSchedule />} />
        )}
        {permissions.some(permission => permission.name === 'Create Movie Schedule') && (
          <Route path="projection/movie_schedule/add" element={<AddMovieSchedule />} />
        )}
        {permissions.some(permission => permission.name === 'Update Movie Schedule') && (
          <Route path="projection/movie_schedule/edit/:id" element={<AddMovieSchedule />} />
        )}
        {permissions.some(permission => permission.name === 'View Movie Schedule') && (
          <Route path="projection/movie_schedule/view/:id" element={<ViewMovieSchedule />} />
        )}

        {/* <Route path='inventory/periodic-data' element={<PeriodicData />} /> */}

        <Route path="*" element={<Navigate to="/error/error404" />} />
        {/* Add remaining routes in a similar manner */}

      </Routes>
    </Suspense>
  );
};

export default DefaultRouter;
