import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import { useStateContext } from "../../../context/ContextProvider";
import personalDetailStoreSchema from "./personal-detail-store-schema";
import axios from "axios";
import employeeService from "../../../services/employee.service";
import { baseUrl } from "../../../base-url";

const PersonalDetails = ({ onSubmit }) => {

  const navigate = useNavigate();
  const { setIsLoading, setPageTitle, showToast } = useStateContext();

  const [employeeID, setEmployeeID] = useState();

  const data = {
    first_name: "",
    middle_name: "",
    last_name: "",
    father_name: "",
    mother_name: "",
    brother_sister_name: "",
    nominee_name: "",
    nominee_relation: "",
    gender: "",
    marital_status: "",
    nationality: "",
    religion: "",
    date_of_birth: "",
    date_of_celebration: "",
    email: "",
    phone_number: "",
    blood_group: "",
    prem_street: "",
    prem_ward_no: "",
    prem_city: "",
    prem_province: "",
    temp_street: "",
    temp_ward_no: "",
    temp_city: "",
    temp_province: "",
    department_id: "",
    date_of_join: "",
    outsource: "",
    reference: "",
    created_by: "",
    updated_by: "",
  };
  /**
   * The function `displayError` takes an error object as input and displays each error message as a
   * toast notification.
   * @param error - The `error` parameter is an object that contains error messages for different
   * columns. Each key in the `error` object represents a column, and the corresponding value is an
   * array of error messages for that column.
   */
  const displayError = (error) => {
    const errArr = Object.keys(error).map((key) => {
      return error[key].join(",");
    });
    errArr.map((column) => showToast(column, "error"));
  };

  const submitForm = (values) => {
    if (employeeID === undefined) {
      setIsLoading(true);
      axios.post(`${baseUrl}/api/employees/registration`, values)
        .then((response) => {
          showToast(`Employee ${values.first_name} details saved.`, "success");
          setEmployeeID(response.data.data.employee_id);
          onSubmit(response.data.data.employee_id);
          setIsLoading(false);
        })
        .catch((error) => {
          setIsLoading(false);
          displayError(error.response.data.errors);
        });
    } else {
      setIsLoading(true);
      const updatedValues = {
        ...values,
        employee_id: employeeID,
      }
      employeeService.update(employeeID, updatedValues)
        .then((response) => {
          showToast(`Employee ${values.first_name} details updated.`, "success");
          setEmployeeID(response.data.data.employee_id);
          onSubmit(response.data.data.employee_id);
          setIsLoading(false);
        })
        .catch((error) => {
          setIsLoading(false);
          displayError(error.response.data.errors);
        });
    }
    // onSubmit(values);
  };

  const setInitialData = () => {
    formik.setValues(data);
  };

  const [genderList, setGenderList] = useState();
  const [maritalList, setMaritalList] = useState();
  const [nomineeList, setNomineeList] = useState();
  const [bloodList, setBloodList] = useState();
  const [departmentList, setDepartmentList] = useState();
  const [outsourceList, setOutsourceList] = useState();

  const attributeData = () => {
    employeeService.employeeInitalData()
      .then((response) => {
        const data = response.data.data;
        setGenderList(data.gender);
        setMaritalList(data.marital_status);
        setNomineeList(data.nominee_relation);
        setBloodList(data.blood_group);
        setDepartmentList(data.department);
        setOutsourceList(data.outsource);
      })
      .catch((error) => {
        displayError(error.response.data.errors);
      })
  };
  useEffect(() => {
    setPageTitle("Employee Registeration");
    setInitialData();
    attributeData();
  }, [setPageTitle]);

  const formik = useFormik({
    initialValues: data,
    validationSchema: personalDetailStoreSchema,
    onSubmit: (values) => {
      onSubmit(values);
    },
  });

  return (
    <>
      <Form onSubmit={formik.handleSubmit} className="form-card text-start">
        <Row className="mb-3">
          <h5>Basic Information</h5>
          <hr className="my-3" />
          <Col md="4">
            <Form.Label htmlFor="validationCustom01">First Name</Form.Label>
            <Form.Control
              type="text"
              name="first_name"
              value={formik.values.first_name}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              isInvalid={
                formik.touched.first_name && !!formik.errors.first_name
              }
            />
            <Form.Control.Feedback type="invalid">
              {formik.errors.first_name}
            </Form.Control.Feedback>
          </Col>
          <Col md="3">
            <Form.Label htmlFor="validationCustomUsername01">
              Middle Name
            </Form.Label>
            <Form.Control
              type="text"
              name="middle_name"
              value={formik.values.middle_name}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              isInvalid={
                formik.touched.middle_name && !!formik.errors.middle_name
              }
            />
            <Form.Control.Feedback type="invalid">
              {formik.errors.middle_name}
            </Form.Control.Feedback>
          </Col>
          <Col md="4">
            <Form.Label htmlFor="validationCustom03">Last Name</Form.Label>
            <Form.Control
              type="text"
              name="last_name"
              value={formik.values.last_name}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              isInvalid={formik.touched.last_name && !!formik.errors.last_name}
            />
            <Form.Control.Feedback type="invalid">
              {formik.errors.last_name}
            </Form.Control.Feedback>
          </Col>
          <Col md="6">
            <Form.Label htmlFor="validationDefault041">Gender</Form.Label>
            <Form.Select
              type="select"
              name="gender"
              value={formik.values.gender}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              isInvalid={formik.touched.gender && !!formik.errors.gender}
            >
              <option value="">Select Gender</option>
              {genderList ? (
                <>
                  {genderList.map((list) => (
                    <option key={list.id} value={list.text}>
                      {list.text}
                    </option>
                  ))}
                </>
              ) : null}
            </Form.Select>
            <Form.Control.Feedback type="invalid">
              {formik.errors.gender}
            </Form.Control.Feedback>
          </Col>
          <Col md="6">
            <Form.Label htmlFor="validationCustomUsername01">
              Marital Status
            </Form.Label>
            <Form.Select
              type="select"
              name="marital_status"
              value={formik.values.marital_status}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              isInvalid={
                formik.touched.marital_status && !!formik.errors.marital_status
              }
            >
              <option value="">Select Marital Status</option>
              {maritalList ? (
                <>
                  {maritalList.map((list) => (
                    <option key={list.id} value={list.text}>
                      {list.text}
                    </option>
                  ))}
                </>
              ) : null}
            </Form.Select>
            <Form.Control.Feedback type="invalid">
              {formik.errors.marital_status}
            </Form.Control.Feedback>
          </Col>
          <Col md="6">
            <Form.Label htmlFor="validationCustom03">Father's Name</Form.Label>
            <Form.Control
              type="text"
              name="father_name"
              value={formik.values.father_name}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              isInvalid={formik.touched.father_name && !!formik.errors.father_name}
            />
            <Form.Control.Feedback type="invalid">
              {formik.errors.father_name}
            </Form.Control.Feedback>
          </Col>
          <Col md="6">
            <Form.Label htmlFor="validationCustom03">Mother's Name</Form.Label>
            <Form.Control
              type="text"
              name="mother_name"
              value={formik.values.mother_name}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              isInvalid={formik.touched.mother_name && !!formik.errors.mother_name}
            />
            <Form.Control.Feedback type="invalid">
              {formik.errors.mother_name}
            </Form.Control.Feedback>
          </Col>
          <Col md="6">
            <Form.Label htmlFor="validationCustom03">Brother/Sister Name</Form.Label>
            <Form.Control
              type="text"
              name="brother_sister_name"
              value={formik.values.brother_sister_name}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              isInvalid={formik.touched.brother_sister_name && !!formik.errors.brother_sister_name}
            />
            <Form.Control.Feedback type="invalid">
              {formik.errors.brother_sister_name}
            </Form.Control.Feedback>
          </Col>
          <Col md="6">
            <Form.Label htmlFor="validationCustom03">Nominee's Name</Form.Label>
            <Form.Control
              type="text"
              name="nominee_name"
              value={formik.values.nominee_name}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              isInvalid={formik.touched.nominee_name && !!formik.errors.nominee_name}
            />
            <Form.Control.Feedback type="invalid">
              {formik.errors.nominee_name}
            </Form.Control.Feedback>
          </Col>
          <Col md="6">
            <Form.Label htmlFor="validationCustom03">Nominee Relation</Form.Label>
            <Form.Select
              type="select"
              name="nominee_relation"
              value={formik.values.nominee_relation}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              isInvalid={
                formik.touched.nominee_relation && !!formik.errors.nominee_relation
              }
            >
              <option value="">Select Nominee Relation</option>
              {nomineeList ? (
                <>
                  {nomineeList.map((list) => (
                    <option key={list.id} value={list.text}>
                      {list.text}
                    </option>
                  ))}
                </>
              ) : null}
            </Form.Select>
            <Form.Control.Feedback type="invalid">
              {formik.errors.nominee_relation}
            </Form.Control.Feedback>
          </Col>
          <Col md="6">
            <Form.Label htmlFor="validationCustom03">Nationality</Form.Label>
            <Form.Control
              type="text"
              name="nationality"
              value={formik.values.nationality}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              isInvalid={formik.touched.nationality && !!formik.errors.nationality}
            />
            <Form.Control.Feedback type="invalid">
              {formik.errors.nationality}
            </Form.Control.Feedback>
          </Col>
          <Col md="6">
            <Form.Label htmlFor="validationCustom03">Religion</Form.Label>
            <Form.Control
              type="text"
              name="religion"
              value={formik.values.religion}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              isInvalid={formik.touched.religion && !!formik.errors.religion}
            />
            <Form.Control.Feedback type="invalid">
              {formik.errors.religion}
            </Form.Control.Feedback>
          </Col>
          <Col md="6">
            <Form.Label htmlFor="validationCustom03">Date of Birth</Form.Label>
            <Form.Control
              type="date"
              name="date_of_birth"
              value={formik.values.date_of_birth}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              isInvalid={formik.touched.date_of_birth && !!formik.errors.date_of_birth}
            />
            <Form.Control.Feedback type="invalid">
              {formik.errors.date_of_birth}
            </Form.Control.Feedback>
          </Col>
          <Col md="6">
            <Form.Label htmlFor="validationCustom03">Date of Celebration</Form.Label>
            <Form.Control
              type="date"
              name="date_of_celebration"
              value={formik.values.date_of_celebration}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              isInvalid={formik.touched.date_of_celebration && !!formik.errors.date_of_celebration}
            />
            <Form.Control.Feedback type="invalid">
              {formik.errors.date_of_celebration}
            </Form.Control.Feedback>
          </Col>
          <Col md="6">
            <Form.Label htmlFor="validationCustom03">Email</Form.Label>
            <Form.Control
              type="text"
              name="email"
              value={formik.values.email}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              isInvalid={formik.touched.email && !!formik.errors.email}
            />
            <Form.Control.Feedback type="invalid">
              {formik.errors.email}
            </Form.Control.Feedback>
          </Col>
          <Col md="6">
            <Form.Label htmlFor="validationCustom03">Phone Number</Form.Label>
            <Form.Control
              type="text"
              name="phone_number"
              value={formik.values.phone_number}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              isInvalid={formik.touched.phone_number && !!formik.errors.phone_number}
            />
            <Form.Control.Feedback type="invalid">
              {formik.errors.phone_number}
            </Form.Control.Feedback>
          </Col>
          <Col md="6">
            <Form.Label htmlFor="validationCustom03">Blood Group</Form.Label>
            <Form.Select
              type="select"
              name="blood_group"
              value={formik.values.blood_group}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              isInvalid={
                formik.touched.blood_group && !!formik.errors.blood_group
              }
            >
              <option value="">Select Blood Group</option>
              {bloodList ? (
                <>
                  {bloodList.map((list) => (
                    <option key={list.id} value={list.text}>
                      {list.text}
                    </option>
                  ))}
                </>
              ) : null}
            </Form.Select>
            <Form.Control.Feedback type="invalid">
              {formik.errors.blood_group}
            </Form.Control.Feedback>
          </Col>
          <h5>Permanent Address</h5>
          <hr className="my-3" />
          <Col md="6">
            <Form.Label htmlFor="validationCustom03">Street</Form.Label>
            <Form.Control
              type="text"
              name="prem_street"
              value={formik.values.prem_street}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              isInvalid={formik.touched.prem_street && !!formik.errors.prem_street}
            />
            <Form.Control.Feedback type="invalid">
              {formik.errors.prem_street}
            </Form.Control.Feedback>
          </Col>
          <Col md="6">
            <Form.Label htmlFor="validationCustom03">Ward Number</Form.Label>
            <Form.Control
              type="text"
              name="prem_ward_no"
              value={formik.values.prem_ward_no}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              isInvalid={formik.touched.prem_ward_no && !!formik.errors.prem_ward_no}
            />
            <Form.Control.Feedback type="invalid">
              {formik.errors.prem_ward_no}
            </Form.Control.Feedback>
          </Col>
          <Col md="6">
            <Form.Label htmlFor="validationCustom03">City</Form.Label>
            <Form.Control
              type="text"
              name="prem_city"
              value={formik.values.prem_city}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              isInvalid={formik.touched.prem_city && !!formik.errors.prem_city}
            />
            <Form.Control.Feedback type="invalid">
              {formik.errors.prem_city}
            </Form.Control.Feedback>
          </Col>
          <Col md="6">
            <Form.Label htmlFor="validationCustom03">Province</Form.Label>
            <Form.Control
              type="text"
              name="prem_province"
              value={formik.values.prem_province}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              isInvalid={formik.touched.prem_province && !!formik.errors.prem_province}
            />
            <Form.Control.Feedback type="invalid">
              {formik.errors.prem_province}
            </Form.Control.Feedback>
          </Col>
          <h5>Temporary Address</h5>
          <hr className="my-3" />
          <Col md="6">
            <Form.Label htmlFor="validationCustom03">Street</Form.Label>
            <Form.Control
              type="text"
              name="temp_street"
              value={formik.values.temp_street}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              isInvalid={formik.touched.temp_street && !!formik.errors.temp_street}
            />
            <Form.Control.Feedback type="invalid">
              {formik.errors.temp_street}
            </Form.Control.Feedback>
          </Col>
          <Col md="6">
            <Form.Label htmlFor="validationCustom03">Ward Number</Form.Label>
            <Form.Control
              type="text"
              name="temp_ward_no"
              value={formik.values.temp_ward_no}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              isInvalid={formik.touched.temp_ward_no && !!formik.errors.temp_ward_no}
            />
            <Form.Control.Feedback type="invalid">
              {formik.errors.temp_ward_no}
            </Form.Control.Feedback>
          </Col>
          <Col md="6">
            <Form.Label htmlFor="validationCustom03">City</Form.Label>
            <Form.Control
              type="text"
              name="temp_city"
              value={formik.values.temp_city}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              isInvalid={formik.touched.temp_city && !!formik.errors.temp_city}
            />
            <Form.Control.Feedback type="invalid">
              {formik.errors.temp_city}
            </Form.Control.Feedback>
          </Col>
          <Col md="6">
            <Form.Label htmlFor="validationCustom03">Province</Form.Label>
            <Form.Control
              type="text"
              name="temp_province"
              value={formik.values.temp_province}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              isInvalid={formik.touched.temp_province && !!formik.errors.temp_province}
            />
            <Form.Control.Feedback type="invalid">
              {formik.errors.temp_province}
            </Form.Control.Feedback>
          </Col>
          <h5>Department Information</h5>
          <hr className="my-3" />
          <Col md="6">
            <Form.Label htmlFor="validationCustom03">Department Name</Form.Label>
            <Form.Select
              type="select"
              name="department_id"
              value={formik.values.department_id}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              isInvalid={formik.touched.department_id && !!formik.errors.department_id}
            >
              <option value="">Select the Department</option>
              {departmentList ? (
                <>
                  {departmentList.map((list) => (
                    <option key={list.id} value={list.id}>
                      {list.text}
                    </option>
                  ))}
                </>
              ) : null}
            </Form.Select>
            <Form.Control.Feedback type="invalid">
              {formik.errors.department_id}
            </Form.Control.Feedback>
          </Col>
          <Col md="6">
            <Form.Label htmlFor="validationCustom03">Date of Training</Form.Label>
            <Form.Control
              type="date"
              name="date_of_join"
              value={formik.values.date_of_join}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              isInvalid={formik.touched.date_of_join && !!formik.errors.date_of_join}
            />
            <Form.Control.Feedback type="invalid">
              {formik.errors.date_of_join}
            </Form.Control.Feedback>
          </Col>
          <Col md="6">
            <Form.Label htmlFor="validationCustom03">Outsource</Form.Label>
            <Form.Select
              type="select"
              name="outsource"
              value={formik.values.outsource}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              isInvalid={formik.touched.outsource && !!formik.errors.outsource}
            >
              <option value="">Select the outsoruce</option>
              {outsourceList ? (
                <>
                  {outsourceList.map((list) => (
                    <option key={list.id} value={list.text}>
                      {list.text}
                    </option>
                  ))}
                </>
              ) : null}
            </Form.Select>
            <Form.Control.Feedback type="invalid">
              {formik.errors.outsource}
            </Form.Control.Feedback>
          </Col>
          <Col md="6">
            <Form.Label htmlFor="validationCustom03">Reference</Form.Label>
            <Form.Control
              type="text"
              name="reference"
              value={formik.values.reference}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              isInvalid={formik.touched.reference && !!formik.errors.reference}
            />
            <Form.Control.Feedback type="invalid">
              {formik.errors.reference}
            </Form.Control.Feedback>
          </Col>
        </Row>
        <p>Note: The next form requires documents to be uploaded <strong>EDUCATION QUALIFICATION CERTIFICATE, DRIVING LINCENSE, CITIZENSHIP, PAN CARD, POLICE CLEARANCE</strong></p>

        <div className="col-12 d-flex flex-wrap gap-2 justify-content-end">
          <Button variant="outline-primary" type="submit">
            Save
          </Button>
          <Button variant="outline-warning" onClick={setInitialData}>
            Reset Data
          </Button>
        </div>
      </Form>
    </>
  );
};

export default PersonalDetails;
