import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  Col,
  Container,
  Form,
  Row,
} from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import { useStateContext } from "../../context/ContextProvider";
import attributeService from "../../services/attribute.service";
import stsaleCloseService from "../../services/stsale-close.service";
import stsaleCloseUpdateSchema from "./stsale-close-update-schema";
import stsaleCloseStoreSchema from "./stsale-close-store-schema";
import StsaleCloseDetail from "./stsale-close-detail/stsale-close-detail";
import OpStsaleCloseDetail from "./op-stsale-close-detail";

const OpAddStsaleClose = () => {
  const navigate = useNavigate();
  const { id, type } = useParams();
  const { setIsLoading, setPageTitle, showToast } = useStateContext();

  // Get today's date and yesterday's date in YYYY-MM-DD format
  // const today = new Date().toISOString().split('T')[0];
  // const yesterday = new Date(Date.now() - 86400000).toISOString().split('T')[0];
  const now = new Date();
  const today = new Date(now.setHours(0, 0, 0, 0)).toISOString().split('T')[0];
  const yesterday = new Date(now.setDate(now.getDate() - 1)).toISOString().split('T')[0];

  const data = {
    shift: "",
    entry_date: today,
    status: "Active",
    created_by: "",
    updated_by: "",
  };

  /**
   * The function `displayError` takes an error object as input and displays each error message as a
   * toast notification.
   * @param error - The `error` parameter is an object that contains error messages for different
   * columns. Each key in the `error` object represents a column, and the corresponding value is an
   * array of error messages for that column.
   */
  const displayError = (error) => {
    let errArr = [];
    errArr = Object.keys(error).map((key) => {
      return error[key].join(",");
    });
    errArr.map((column) =>
      showToast(column, "error")
    );
  }

  const store = (values) => {
    setIsLoading(true);
    const type = values.shift === "Morning" ? 'mor' : values.shift === "Evening" ? 'even' : "";
    stsaleCloseService
      .store(values, type)
      .then((response) => {
        setIsLoading(false);
        navigate(`/app/stsale_close/${type}/edit/${response.data.data.close_id}`);
        showToast(`New Stsale Close added.`, "success");
      })
      .catch((error) => {
        setIsLoading(false);
        displayError(error.response.data.errors);
      });
  };

  const update = (values) => {
    setIsLoading(true);
    const type = values.shift === "Morning" ? 'mor' : values.shift === "Evening" ? 'even' : "";
    stsaleCloseService
      .update(id, values, type)
      .then((response) => {
        setIsLoading(false);
        showToast(`Stsale Close updated.`, "success");
      })
      .catch((error) => {
        setIsLoading(false);
        displayError(error.response.data.errors);
      });
  };

  const submitForm = (values) => {
    id === undefined || id === "0" ? store(values) : update(values);
  };

  const setInitialData = () => {
    if (id !== undefined) {
      setIsLoading(true);
      stsaleCloseService
        .singleView(id, type)
        .then((response) => {
          if (response.status === 200) {
            formik.setValues(response.data.data);
          }
        })
        .catch((error) => {
          showToast(error.response.data.message, "error");
          navigate(`/app/stsale_close/${type}`);
        });
      setIsLoading(false);
    } else {
      formik.setValues(data);
    }
  };

  const [closeType, setCloseType] = useState();
  const [shift, setShift] = useState();

  //get the stsaleclose type
  const getCloseType = () => {
    attributeService.childrenList("Close Type")
      .then((response) => {
        setCloseType(response.data);
      })
      .catch((error) => {
        displayError(error.response.data.errors);
      })
    attributeService.childrenList("Shift")
      .then((response) => {
        setShift(response.data);
      })
      .catch((error) => {
        displayError(error.response.data.errors);
      })
  }

  useEffect(() => {
    setPageTitle("Staff Closes");
    setInitialData();
    getCloseType();
  }, [setPageTitle]);

  const formik = useFormik({
    initialValues: data,
    validationSchema: id !== undefined ? stsaleCloseUpdateSchema : stsaleCloseStoreSchema,
    onSubmit: submitForm,
  });

  return (
    <Container>
      <Card>
        <Card.Header className="d-flex justify-content-between">
          <div className="header-title">
            <h4 className="card-title">
              {id ? "Edit Staff Closing" : "Add Staff Closing"}
            </h4>
          </div>
        </Card.Header>
        <Card.Body>
          <Form onSubmit={formik.handleSubmit}>
            <Row className="mb-3">
              <h5>Basic Information</h5>
              <hr className="my-3" />
              <Col md="6">
                <Form.Label htmlFor="validationDefault041">
                  Shift
                </Form.Label>
                <Form.Select
                  className="form-control form-select"
                  name="shift"
                  value={formik.values.shift}
                  onChange={(e) => {
                    formik.setFieldValue('shift', e.target.value);
                  }}
                  onBlur={formik.handleBlur}
                  isInvalid={
                    formik.touched.shift && !!formik.errors.shift
                  }
                >
                  <option value="">Select the shift</option>
                  {shift ? (
                    <>
                      {
                        shift.map((list) => (
                          <option key={list.id} value={list.text}>{list.text}</option>
                        ))
                      }
                    </>
                  ) :
                    null
                  }
                </Form.Select>
                <Form.Control.Feedback type="invalid">
                  {formik.errors.shift}
                </Form.Control.Feedback>
              </Col>
              <Col md="6">
                <Form.Label htmlFor="validationCustom01">
                  Entry Date
                </Form.Label>
                <Form.Control
                  type="date"
                  name="entry_date"
                  value={formik.values.entry_date}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  max={today} // Setting max date to today's date
                  min={yesterday} // Setting min date to yesterday's date
                  isInvalid={
                    formik.touched.entry_date &&
                    !!formik.errors.entry_date
                  }
                />
                <Form.Control.Feedback type="invalid">
                  {formik.errors.entry_date}
                </Form.Control.Feedback>
              </Col>
            </Row>
          </Form>
        </Card.Body>
      </Card>
      {formik.isValid && formik.touched ? (
        <Card>
          <Card.Body>
            <OpStsaleCloseDetail close_data={formik.values} />
          </Card.Body>
        </Card>
      ) : null}
    </Container>
  );
};

export default OpAddStsaleClose;
