import React, { useEffect, useState } from "react";
import { Row, Col, Card } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { useStateContext } from "../../context/ContextProvider";
import PersonalDetails from "./personal-details/personal-details";
import AddEmployeeDocs from "./employee-docs/add-employee-docs";
import axios from "axios";
import { baseUrl } from "../../base-url";
import Swal from "sweetalert2";

const AddEmployee = () => {
  const navigate = useNavigate();
  const { setIsLoading, setPageTitle, showToast } = useStateContext();
  const [show, setShow] = useState("A");
  const [employeeID, setEmployeeID] = useState(null);
  const [formData, setFormData] = useState({
    personalDetails: {},
    documentDetails: {}
  });

  const displayError = (error) => {
    let errArr = [];
    errArr = Object.keys(error).map((key) => {
      return error[key].join(",");
    });
    errArr.forEach((column) => showToast(column, "error"));
  };

  const handleAddDocs = (values) => {
    setEmployeeID(values);
    setShow("add_docs");
  };

  const handleClosePersonal = () => {
    setShow('A');
  }
  // new code
  const handlePersonalDetailsSubmit = (data) => {
    setFormData({ ...formData, personalDetails: data });
    try {
      axios.post(`${baseUrl}/api/employees/registration`, data)
        .then((response) => {
          showToast(`New Employee ${data.first_name} added.`, "success");
          navigate('/app/employee');
          const employee_id = response.data.data.employee_id;
          const updatedValues = {
            ...data,
            term_and_conditions: 1,
            employee_id: employee_id,
          }
        })
        .catch((error) => {
          setIsLoading(false);
          displayError(error.response.data.errors);
        })
    } catch (error) {
      setIsLoading(false);
      displayError(error.response.data.errors);
    }
    // setShow("add_docs");
  };
  const handleDocumentDetailsSubmit = async (data) => {
    const completeData = { ...formData.personalDetails, ...data };
    setIsLoading(true);
    try {
      axios.post(`${baseUrl}/api/employees/registration`, formData.personalDetails)
        .then((response) => {
          showToast(`New Employee ${formData.personalDetails.first_name} added.`, "success");
          navigate('/app/employee');
          const employee_id = response.data.data.employee_id;
          const updatedValues = {
            ...data,
            term_and_conditions: 1,
            employee_id: employee_id,
          }
          // axios.post(`${baseUrl}/api/employees/registration/docs`, updatedValues, {
          //   headers: {
          //     'Content-Type': 'multipart/form-data',
          //   },
          // })
          //   .then((response) => {
          //     showToast(`New Employee ${formData.personalDetails.first_name} added.`, "success");
          //     setIsLoading(false);
          //   })
          //   .catch((error) => {
          //     setIsLoading(false);
          //     displayError(error.response.data.errors);
          //   })
        })
        .catch((error) => {
          setIsLoading(false);
          displayError(error.response.data.errors);
        })
    } catch (error) {
      setIsLoading(false);
      displayError(error.response.data.errors);
    }
  };

  const handleBackToPersonal = () => {
    setShow('A');
  }

  useEffect(() => {
    setPageTitle("Employee");
  }, [setPageTitle]);

  return (
    <Row>
      <Col sm="12" lg="12">
        <Card>
          <Card.Header className="d-flex justify-content-between">
            <div className="header-title">
              <h4 className="card-title">Employee Registration Form</h4>
            </div>
          </Card.Header>
          <Card.Body>
            <div id="form-wizard1" className="text-center mt-3">
              <ul
                id="top-tab-list"
                className="p-0 row list-inline d-flex flex-wrap"
              >
                <li
                  className={` ${show === "add_docs" ? " active done" : ""
                    } col-lg-6 col-md-6 text-start mb-2 active`}
                  id="personal"
                >
                  <Link to="#">
                    <div className="iq-icon me-3">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        height="20"
                        width="20"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z"
                        />
                      </svg>
                    </div>
                    <span>Personal Details</span>
                  </Link>
                </li>
                <li
                  id="add_docs"
                  className={`${show === "personal" ? " active done" : ""}${show === "add_docs" ? " active" : ""
                    } col-lg-6 col-md-6 mb-2 text-start`}
                >
                  <Link to="#">
                    <div className="iq-icon me-3">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        height="20"
                        width="20"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z"
                        />
                      </svg>
                    </div>
                    <span>Upload Documents</span>
                  </Link>
                </li>
              </ul>
              <fieldset className={`${show === "A" ? "d-block" : "d-none"}`}>
                {/* <PersonalDetails onSubmit={handleAddDocs} /> */}
                <PersonalDetails onSubmit={handlePersonalDetailsSubmit} />
              </fieldset>
              <fieldset className={`${show === "add_docs" ? "d-block" : "d-none"}`}>
                {/* <AddEmployeeDocs employee_id={employeeID} onClose={handleClosePersonal} /> */}
                <AddEmployeeDocs onSubmit={handleDocumentDetailsSubmit} onBack={handleBackToPersonal} />
              </fieldset>
            </div>
          </Card.Body>
        </Card>
      </Col>
    </Row>
  );
};

export default AddEmployee;
